import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        user: null,
        userIsAuthenticated: false,
        message: 'message',
        io: {},
        openingDefense: {},
    },
    mutations: {
        saveUser: (state, user) => {
            state.user = user
        },

        deleteUser: state => {
            state.user = null
        },

        saveOpeningDefense: (state, openingDefense) => {
            state.openingDefense = openingDefense
        },

        getOpeningDefense: state => {
            return state.openingDefense
        },

        setUserIsAuthenticated: state => {
            state.userIsAuthenticated = true
        },

        setUserIsNotAuthenticated: state => {
            state.userIsAuthenticated = false
        },

        setSocket: (state, socket) => {
            state.io = socket
            console.log('Socket de un cliente connectado')
        },
    },
    actions: {
        socket_msg: ({ rootState }, message) => {
            rootState.io.emit('message', message)
        },
    },
    modules: {},
    getters: {
        isLoggedIn: state => {
            return state.user !== null
        },
    },
    plugins: [createPersistedState()],
})
