<template>
  <div class="main-component">
    <v-app id="inspire">
    <router-view></router-view>
    </v-app>
  </div>
</template>

<script>
export default {
  name: 'app',
}  
</script>

<style lang="scss" scoped>

#inspire {
    height: 100%;
}
    
</style>
