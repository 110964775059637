import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Vuex from 'vuex'
import store from './store/'
import gameModule from './modules/gamemodule'
import messageModule from './modules/messagemodule'
import VueSocketIO from 'vue-socket.io'
import SocketIO from 'socket.io-client'
import io from 'socket.io-client'
import vuetify from './plugins/vuetify'
import axios from 'axios'
import '../src/styles/global.css'
import chart from './plugins/Chart.vue'
import moment from './plugins/moment'
import createPersistedState from 'vuex-persistedstate'

Vue.config.productionTip = false
Vue.prototype.$http = axios

Vue.use({
    install(Vue) {
        Vue.prototype.$api = axios.create({
            baseURL: 'https://pchessbackend.herokuapp.com',
        })
    },
})

Vue.component('chart', chart)
Vue.use(moment)

const accessToken = localStorage.getItem('accessToken')
if (accessToken) {
    Vue.prototype.$http.defaults.headers.common['Authorization'] = accessToken
}

Vue.use(
    new VueSocketIO({
        debug: true,
        connection: SocketIO('https://pchessbackend.herokuapp.com', {
            withCredentials: false,
            //transports: ['websocket'],
        }),
        vuex: {
            store,
            actionPrefix: 'SOCKET_',
            mutationPrefix: 'SOCKET_',
        },
    })
)
Vue.use(Vuex)

window.$ = window.jQuery = require('jquery')

//Inciancia de VUE
new Vue({
    store,
    router,
    vuetify,
    render: h => h(App),
}).$mount('#app')
