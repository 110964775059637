import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'

Vue.use(VueRouter)

const routes = [
    {
        path: '/routing',
        name: 'Routing',
        component: () =>
            import(/* webpackChunkName: "about" */ '../views/Routing.vue'),
        meta: {
            requiresAuth: true,
        },
        children: [
            {
                path: '/profile/:name',
                name: 'Profile',
                //redirect: '/profile/:name',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () =>
                    import(
                        /* webpackChunkName: "about" */ '../views/Profile.vue'
                    ),
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: '/account',
                name: 'Account',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () =>
                    import(
                        /* webpackChunkName: "about" */ '../views/Account.vue'
                    ),
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: '/play',
                name: 'Play',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () =>
                    import(/* webpackChunkName: "about" */ '../views/Play.vue'),
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: '/home',
                name: 'Home',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () =>
                    import(/* webpackChunkName: "about" */ '../views/Home.vue'),
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: '/challenge',
                name: 'Challenge',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () =>
                    import(
                        /* webpackChunkName: "about" */ '../views/Challenge.vue'
                    ),
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: '/learn',
                name: 'Learn',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () =>
                    import(
                        /* webpackChunkName: "about" */ '../views/Learn.vue'
                    ),
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: '/watch',
                name: 'Watch',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () =>
                    import(
                        /* webpackChunkName: "about" */ '../views/Watch.vue'
                    ),
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: '/messages',
                name: 'Messages',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () =>
                    import(
                        /* webpackChunkName: "about" */ '../views/Messages.vue'
                    ),
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: '/SingleOpeningDefense',
                name: 'SingleOpeningDefense',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () =>
                    import(
                        /* webpackChunkName: "about" */ '../views/SingleOpeningDefense.vue'
                    ),
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: '/AcademyExercise',
                name: 'AcademyExercise',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () =>
                    import(
                        /* webpackChunkName: "about" */ '../views/AcademyExercise.vue'
                    ),
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: '/WatchGame/:id',
                name: 'WatchGame',

                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () =>
                    import(
                        /* webpackChunkName: "about" */ '../views/WatchGame.vue'
                    ),
                props: true,
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: '/WatchGameOnline/:id/:r_id',
                name: 'WatchGameOnline',

                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () =>
                    import(
                        /* webpackChunkName: "about" */ '../views/WatchGameOnline.vue'
                    ),
                props: true,
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: '/friends/',
                name: 'Friends',

                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () =>
                    import(
                        /* webpackChunkName: "about" */ '../views/Friends.vue'
                    ),
                props: true,
                meta: {
                    requiresAuth: true,
                },
            },
        ],
    },
    {
        path: '/start',
        name: 'Start',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "about" */ '../views/Start.vue'),
    },
    {
        path: '/',
        redirect: '/home',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
    },
]

const router = new VueRouter({
    routes,
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        // this route requires auth, check if logged in
        // if not, redirect to login page.
        if (!store.getters.isLoggedIn) {
            next({ name: 'Start' })
        } else {
            next() // go to wherever I'm going
        }
    } else {
        next() // does not require auth, make sure to always call next()!
    }
})

export default router
